<template>
  <div id="app">
    <el-container class="container" style="height: 100%">
      <el-aside width="200px">
        <el-menu background-color="#324157" style="width: 100%"
                 text-color="#fff" router :default-active="activeIndex">
          <el-menu-item index="/diary">
            <span>日报列表</span>
          </el-menu-item>
          <el-menu-item index="/todo">
            <span>待办</span>
          </el-menu-item>
          <el-menu-item index="/diary/add">
            <span>新建日报</span>
          </el-menu-item>
          <el-menu-item index="/user">
            <span>用户中心</span>
          </el-menu-item>
        </el-menu>
      </el-aside>

      <el-container>
        <el-main>
          <router-view :key="$route.path" />
        </el-main>
      </el-container>
    </el-container>

    <el-footer style="margin:0 auto; text-align:center">
      <a href="https://beian.miit.gov.cn" target="_blank">粤ICP备20025559号</a>
    </el-footer>>
  </div>
</template>

<style>
/*.container {*/
/*  max-width: 1000px;*/
/*}*/
.el-aside {
  display: block;
  position: relative;
  overflow-y: scroll;
  background-color: #324157;
}
.el-menu {
  position: relative;
  top: 8%;
}

</style>
<script>
import Header from "./components/Header";
export default {
  components: {Header},
  data() {
    return {
      // index: this.$store.getters.getIndex
    }
  },
  computed: {
    activeIndex() {
      return this.$route.path
    }
  }
}
</script>
