import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: '',
    // userInfo: {}
    userInfo: JSON.parse(sessionStorage.getItem("userInfo")),
  },
  mutations: {

    SET_TOKEN(state, token) {
      state.token = token;
      localStorage.setItem("token", token)
    },
    SET_USERINFO(state, userInfo) {
      state.userInfo = userInfo;
      //sessionStorage只能存一些字符串
      sessionStorage.setItem("userInfo", JSON.stringify(userInfo))
    },
    REMOVE_INFO(state) {
      state.token = ''
      state.userInfo = {}
      localStorage.setItem("token", "")
      sessionStorage.setItem("userInfo", JSON.stringify(""))
    }

  },
  getters: {
    getUser: state => {
      return state.userInfo
    }
  },
  actions: {
  },
  modules: {
  }
})
