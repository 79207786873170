import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    redirect: { name: 'Diary'}
  },
  {
    path: '/user',
    name: 'User',
    component: () => import('../views/User.vue')
  },
  {
    path: '/diary',
    name: 'Diary',
    meta: {
      requireAuth: true
    },
    component: () => import('../views/Diary.vue')
  },
  {
    path: '/diary/add',
    name: 'DiaryAdd',
    meta: {
      requireAuth: true
    },
    component: () => import('../views/DiaryEdit.vue')
  },
  {
    path: '/diary/:diaryId',
    name: 'DiaryDetail',
    component: () => import('../views/DiaryDetail.vue')
  },
  {
    path: '/diary/:diaryId/edit',
    name: 'DiaryEdit',
    meta: {
      requireAuth: true
    },
    component: () => import('../views/DiaryEdit.vue')
  },
  {
    path: '/todo',
    name: 'Todo',
    meta: {
      requireAuth: true
    },
    component: () => import('../views/Todo.vue')
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
