import axios from 'axios'
import Element from 'element-ui'
import router from './router'
import store from './store'

// axios.defaults.baseURL = "https://mizaki008.top:8081"
//axios.defaults.baseURL = "http://localhost:8081"
axios.defaults.baseURL = "http://101.33.231.86:8081"

//前置拦截
axios.interceptors.request.use(config => {
  //  注意拦截完成后需要将config返回
  return config
})

//后置拦截,这里的response就是服务器返回的数据
axios.interceptors.response.use(response => {
      let res = response.data

      console.log("后置拦截器中的res")
      console.log(res)

      if (res.code === 200) {

        return response

      } else {

        //使用element-ui的弹窗
        Element.Message.error(res.msg, {duration: 3 * 1000})

        //如果后端返回的code不是200就应该阻止axios请求的后续逻辑
        return Promise.reject(res.msg)
      }
    },
    //  如果后端出现异常，会调用onRejected方法，传入error参数
    error => {
      console.log(error.response)
      //当data不为空时（说明后端返回了result）
      if (error.response.data) {
        //覆盖error的message为我们自定义的消息
        error.message = error.response.data.msg
      }

      if (error.response.status === 401) {
        store.commit("REMOVE_INFO")
        router.push("/user")
      }

      Element.Message.error(error.message, {duration: 3 * 1000})

      //如果后端返回异常就应该阻止axios请求的后续逻辑
      return Promise.reject(error)
    }
)
