<template>
    <el-header style="text-align: right; font-size: 12px">
      <el-dropdown @command="logout" v-if="hasLogin">
        <i class="el-icon-setting" style="margin-right: 15px"></i>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item>注销</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <span>{{ username }}</span>
    </el-header>
</template>

<script>
  import img from "../assets/bro.png"
  export default {
    name: "Header",
    data() {
      return {
        // user: {
        //   username: '请先登录',
        //   avatar: ''
        // },
        // hasLogin: false
      }
    },
    props: {
      hasLogin: {
        type: Boolean,
        default: false,
        required: true
      },
      username: {
        type: String,
        default: "请先登录",
        required: true
      }
    },
    methods: {
      logout() {
        const _this = this
        this.$axios.get("/logout", {
          headers: {
            //加入参数
            "Authorization": localStorage.getItem("token")
          }
        }).then(res => {
          this.$store.commit("REMOVE_INFO")
          this.$router.go("/user")
        })
      }
    },
    activated() {
      if (this.$store.getters.getUser) {
        console.log(this.$store.getters.getUser)
        this.user.username = this.$store.getters.getUser.username
        this.user.avatar = this.$store.getters.getUser.avatar

        this.hasLogin = true
      }
    }
  }
</script>

<style scoped>

  .header {
    margin: 0 auto;
    text-align: center;
  }

  .maction {
    margin-top: 10px;
    margin-bottom: 20px;
  }
</style>
